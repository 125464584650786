<template>
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item mr-auto">
      <b-link
        class="navbar-brand"
        to="/"
      >
        <logo />
      </b-link>
    </li>
  </ul>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import Logo from './Logo.vue'

export default {
  components: {
    BLink,
    Logo,
  },
}
</script>
