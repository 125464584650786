<template>
  <div class="navbar-container d-flex content align-items-center">
    <b-link
      v-if="layoutType === 'horizontal'"
      class="navbar-brand d-xl-block d-none"
      to="/"
    >
      <logo />
    </b-link>

    <div
      v-if="isNavMenuHidden"
      class="custom-horizontal-nav header-navbar navbar-expand-sm navbar navbar-horizontal navbar-light menu-border d-none d-xl-block"
    >
      <horizontal-nav-menu />
    </div>

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0 text-capitalize">
              {{ userName }}
            </p>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            :text="userInitials"
          />
        </template>

        <b-dropdown-item
          :href="adminUrl"
          target="_blank"
        >
          <feather-icon
            size="16"
            icon="ExternalLinkIcon"
            class="mr-50"
          />

          <span>View Admin</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logoutAll"
        >
          <feather-icon
            size="16"
            icon="PowerIcon"
            class="mr-50"
          />
          <span>Logout All</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BAvatar,
} from 'bootstrap-vue'
import axios from 'axios'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import HorizontalNavMenu from '@core/layouts/layout-horizontal/components/horizontal-nav-menu/HorizontalNavMenu.vue'
import useAppCustomizer from '@core/layouts/components/app-customizer/useAppCustomizer'
import { $themeConfig } from '@themeConfig'

import getEnv from '@/utils/env'
import Logo from './Logo.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    Logo,
    HorizontalNavMenu,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      adminUrl: getEnv('VUE_APP_ADMIN_URL'),
    }
  },
  computed: {
    appName() {
      return $themeConfig.app.appName
    },
    userName() {
      return this.$store.getters['auth/userName']
    },
    userInitials() {
      if (!this.userName) {
        return ''
      }
      return this.userName.split(' ').slice(0, 3).map(name => name.charAt(0)).join('')
    },
  },
  setup() {
    const {
      isNavMenuHidden,
      layoutType,
    } = useAppCustomizer()

    return {
      isNavMenuHidden,
      layoutType,
    }
  },
  methods: {
    logout() {
      axios
        .post('/auth/logout')
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error?.response?.data?.detail || 'Something went wrong',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })

      this.$store.dispatch('auth/clearAuthData')
      this.$router.push({ name: 'login' })
    },
    logoutAll() {
      axios
        .post('/auth/logoutall')
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully logged out from all devices',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$store.dispatch('auth/clearAuthData')
          this.$router.push({ name: 'login' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error?.response?.data?.detail || 'Something went wrong',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
